var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"term-table-container"},[_c('CCard',{staticClass:"term-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,_vm.dateFormat)))])]}},{key:"applied_date",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.applied_date)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.applied_date,_vm.dateFormat)))]):_vm._e()])]}},{key:"is_published",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.is_published)?_c('CIcon',{staticClass:"publish-icon",attrs:{"name":"cil-check"}}):_vm._e()],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"width":"50px"}},[(_vm.isAllowedViewDetails)?_c('button',{staticClass:"btn btn-view-details",on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }