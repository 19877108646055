<template>
  <div class="terms-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="9" sm="12">
            <h1>{{$t("TERM_LIST_PAGE_TITLE")}}</h1>
          </CCol>
          <CCol md="3" sm="12">
            <div class="text-right">
              <CButton class="btn btn-create" @click="create">{{$t("TERM_LIST_PAGE_CREATE_TERM")}}</CButton>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <TermListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
          :isAllowedViewDetails="isAllowedViewDetails"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import TermListTable from '@/components/Term/TermListTable';
import StakingPagination from '@/components/StakingPagination';
import { endpoints, env, Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';

export default {
  name: 'TermList',
  components: {
    TermListTable,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      isAllowedViewDetails: false,
      isAllowedCreating: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();
    this.getTerms();
  },

  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'VIEW_TERM_DETAIL') {
            this.isAllowedViewDetails = true;
          }

          if (item.name === 'CREATE_TERM') {
            this.isAllowedCreating = true;
          }
        });
      }
    },
    async getTerms(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getTerms, { params });

        this.items = result.data.items || [];
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CLAIM_REQUEST_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getTerms();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getTerms();
    },
    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.getTerms(data);
    },
    create() {
      this.$router.push('terms/create');
    },
  },
};
</script>
<style lang="scss">
.terms-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }

      .btn-create {
        display: inline-block;
        background: #3b4b7e;
        border: none;
        border-radius: 32px;
        min-width: 150px;
        height: 32px;
        color: #fff;
        margin: 0;
      }
    }

    .notification {
      color: red;
      margin: 5px 0 20px 0;
      font-size: 13px;
    }
  }
}
</style>
