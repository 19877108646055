<template>
  <div class="term-table-container">
    <CCard class="term-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template #created_at="{item}">
          <td>{{item.createdAt | moment(dateFormat)}}</td>
        </template>

        <template #applied_date="{item}">
          <td>
            <span v-if="item.applied_date">{{item.applied_date | moment(dateFormat)}}</span>
          </td>
        </template>

        <template #is_published="{item}">
          <td>
            <CIcon v-if="item.is_published" class="publish-icon" name="cil-check" />
          </td>
        </template>

        <template #action="{item}">
          <td width="50px">
            <button
              class="btn btn-view-details"
              v-if="isAllowedViewDetails"
              @click="viewDetails(item)"
            >
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Constants } from '@/constants';

export default {
  name: 'TermListTable',
  components: {},
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'term_no',
          label: this.$t('TERM_LIST_TABLE_COMPONENT_TERM_NUMBER_COL'),
        },
        {
          key: 'applied_date',
          label: this.$t('TERM_LIST_TABLE_COMPONENT_APPLIED_DATE_COL'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'is_published',
          label: this.$t('TERM_LIST_TABLE_COMPONENT_PUBLISHED_COL'),
        },
        {
          key: 'created_at',
          label: this.$t('TERM_LIST_TABLE_COMPONENT_CREATED_AT_COL'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
    isAllowedViewDetails: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`terms/${item.term_no}/details`);
    },
  },
};
</script>

<style lang="scss">
.term-table-container {
  .term-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }

      .publish-icon {
        color: #5da30b;
      }

      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>
